interface GeoBounds {
  minLat: number;
  minLng: number;
  maxLat: number;
  maxLng: number;
  initialLat: number;
  initialLng: number;
}
export const bounds: GeoBounds = {
  minLat: 36.316111,
  minLng: 139.001944,
  maxLat: 36.5625,
  maxLng: 139.23,
  initialLat: 36.42,
  initialLng: 139.12,
};

export const initialZoom:number = 11;

interface AddressNames {
  country: string;
  administrativeArea: string;
  locality: string;
}
export const address: AddressNames = {
  country: "jp",
  administrativeArea: "群馬県",
  locality: "前橋市",
}

export const freezingMapTileUrl:string = "https://data-compass-tenchijin-co-jp.s3.ap-northeast-1.amazonaws.com/api/freezing-map/maebashi/22-23-lt_-4/{z}/{x}/{y}.png";
export const logoImageUrl:string = "img/maebashi-logo.png";
export const cityWebsiteUrl:string = "https://www.city.maebashi.gunma.jp/soshiki/suidokyoku/index.html";
export const areaGeoJsonUrl:string = "./geojson/maebashi_kyokai.geojson";