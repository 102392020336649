import styles from "./css/LayerOpacityControlBar.module.scss";
import { Legend } from "./Legend";

type Prop = {
  handleChangeOpacity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  opacity: number;
};

const LayerOpacityControlBar = (props: Prop) => {
  return (
    <aside className={styles.aside}>
      <div className={styles.wrapper}>

        <div className={styles.legend}>
          <p className={styles["legend-title"]}>
            地表面温度がマイナス4℃以下になった日数
          </p>
          <div className={styles.colorpalette}>
            <div>
              <div className={styles.value}>1日</div>
              <div className={`${styles.colorbox} ${styles.colorbox1}`}></div>
            </div>
            <div>
              <div className={styles.value}>2日</div>
              <div className={`${styles.colorbox} ${styles.colorbox2}`}></div>
            </div>
            <div>
              <div className={styles.value}>3日以上</div>
              <div className={`${styles.colorbox} ${styles.colorbox3}`}></div>
            </div>
          </div>
        </div>

        <div className={styles["opacity-slider"]}>
          <label htmlFor="toyota" className={styles.label}>
            重ねる色の透明度の調整
          </label>
          <input
            className={styles.slider}
            type="range"
            min="0"
            max="80" // 透明度を設定するレイヤーの初期値に合わせる
            onChange={props.handleChangeOpacity}
            value={props.opacity}
          />
        </div>
      </div>
    </aside>
  );
};

export { LayerOpacityControlBar };
