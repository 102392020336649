import styles from "./css/Layout.module.scss";

import { HazardMap } from "../components/HazardMap";
import { Header } from "../components/Header";

function Layout() {
  return (
    <div className={styles.layout}>
      <Header />
      <HazardMap />
    </div>
  );
}

export { Layout };
