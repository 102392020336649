import styles from "./css/Legend.module.scss";

const Legend = () => {
  return (
    <div className={styles.legend}>
      <p className={styles["legend-title"]}>
        地表面温度が<br/>マイナス4℃以下<br/>になった日数
      </p>
      <div className={styles.colorpalette}>
        <div>
          <div className={styles.value}>1日</div>
          <div className={`${styles.colorbox} ${styles.colorbox1}`}></div>
        </div>
        <div>
          <div className={styles.value}>2日</div>
          <div className={`${styles.colorbox} ${styles.colorbox2}`}></div>
        </div>
        <div>
          <div className={styles.value}>3日以上</div>
          <div className={`${styles.colorbox} ${styles.colorbox3}`}></div>
        </div>
      </div>
    </div>
  );
};

export { Legend };
