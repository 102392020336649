import styles from "./css/LocationSearchForm.module.scss";
import AutoComplete from "react-google-autocomplete";
import { address } from "../constants";
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
type Props = {
  handleSubmit: (
    value: string
  ) => (event: React.FormEvent<HTMLFormElement>) => void;
  searchParam: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
};

export default function LocationSearchForm(props: Props) {
  return (
    <form
      className={styles["location-search-form"]}
      onSubmit={props.handleSubmit(props.searchParam)}
      style={{display:"flex", justifyContent: "end"}}
    >
      <AutoComplete
        className={styles.locationSearchFormInput}
        apiKey={apiKey}
        onChange={(event) => {
          const result = (event.target as HTMLInputElement).value;
          props.setSearchParam(result);
        }}
        onPlaceSelected={(place) => {
          if (!place) return;
          props.setSearchParam(`${place.name}`);
        }}
        language="ja"
        defaultValue={address.administrativeArea + address.locality}
        options={{
          types: ["(regions)"],
          fields: ["name"],
          componentRestrictions: { country: "jp" },
        }}
      />

      <button type="submit" className="fas fa-search"></button>
    </form>
  );
}
