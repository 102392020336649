import { useState } from "react";
import styles from "./css/Header.module.scss";
import {address, logoImageUrl, cityWebsiteUrl} from "../constants";

const Header = () => {
  const [isOpenHeader, setIsOpenHeader] = useState(false);

  const CloseButton = (props: { isOpenHeader: boolean }) => {
    const isOpenHeader = props.isOpenHeader;
    if (isOpenHeader) {
      return (
        <button
          onClick={() => setIsOpenHeader(!isOpenHeader)}
          className={styles.closeButton}
        >
          閉じる
        </button>
      );
    }
    return null;
  };

  return (
    <header className={`${styles.header} ${isOpenHeader ? styles.open : ""}`}>
      <div className={styles.title}>
        <div className={styles["title__left"]}>
          <a
            href={cityWebsiteUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logoImageUrl}
              alt={address.locality+"ロゴ"}
              className={styles["city-logo"]}
            />
          </a>
        </div>
        <div className={styles["title__right"]}>
          <p className={styles["title__right__text"]}>
            水道管凍結注意<span className={styles["small-text"]}>マップ</span>
          </p>
          <div className={styles["title__right__compass"]}>
            <a
              href="https://tenchijin.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Powered by</span>
              <img
                src="img/tenchijin-compass-logo.svg"
                alt="Tenchijin COMPASS ロゴ"
                className={styles["compass-logo"]}
              />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.text}>
        <p>このマップは冬季に水道管が凍結するリスクを可視化したものです。</p>
        <p>
          2022年12月から2023年3月までの期間で、人工衛星から取得する地表面温度が-4℃を下回る日数を3段階で表示しています。
        </p>
        <p>
          群馬県前橋市と株式会社天地人が土地評価プラットフォーム「Tenchijin COMPASS」を使用し、作成しました。
        </p>
        <CloseButton isOpenHeader={isOpenHeader} />
      </div>

      <button
        className={styles.infoButton}
        onClick={() => setIsOpenHeader(!isOpenHeader)}
      >
        <i className="fas fa-info-circle"></i>
        この地図について
      </button>
    </header>
  );
};

export { Header };
