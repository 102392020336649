import { useRef, useEffect, useState } from "react";
import {
  FitBoundsOptions,
  LngLatBoundsLike,
  Map,
  StyleSpecification,
} from "maplibre-gl";
 import maplibregl from "maplibre-gl";
import "./css/map.scss";
import { MapOptions } from "maplibre-gl/dist/maplibre-gl";
import LocationSearchForm from "./LocationSearchForm";
import { LayerOpacityControlBar } from "./LayerOpacityControlBar";
import { Legend } from "./Legend";
import { bounds, initialZoom, address, freezingMapTileUrl, areaGeoJsonUrl } from "../constants";
// @ts-ignore
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
// @ts-ignore
maplibregl.workerClass = maplibreglWorker;
declare const google: any;
declare const swal: any;

const HazardMap: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement>(null!);
  const map = useRef<Map>(null!);
  const [zoom] = useState(initialZoom);
  const [opacity, setOpacity] = useState(80);
  const [searchParam, setSearchParam] = useState("");
  const hazardLayerName = "hazard";

  const searchGeocode = async (
    placeName: string
  ): Promise<LngLatBoundsLike | null> => {
    const geocoder = new google.maps.Geocoder();

    const componentRestrictions = {
      country: address.country,
      administrativeArea: address.administrativeArea,
      locality: address.locality,
    };

    const geocodeRequest = {
      address: placeName,
      componentRestrictions,
    };
    const geocoderResult = await geocoder.geocode(geocodeRequest);

    const isValidSearchResult: boolean = geocoderResult.results.length === 1;
    const searchAddress: string = geocoderResult.results[0].formatted_address;
    if (!isValidSearchResult || !searchAddress.includes(address.administrativeArea+address.locality)) {
      swal(
        `入力された地名が見つかりませんでした。\n${address.locality}内の地名を再度検索してください`
      );
      return null;
    }

    const northEast = geocoderResult.results[0].geometry.bounds.getNorthEast();
    const southWest = geocoderResult.results[0].geometry.bounds.getSouthWest();

    const northEastLat = northEast.lat();
    const northEastLng = northEast.lng();
    const southWestLat = southWest.lat();
    const southWestLng = southWest.lng();
    const lngLatBoundsLike: LngLatBoundsLike = [
      southWestLng,
      southWestLat,
      northEastLng,
      northEastLat,
    ];
    return lngLatBoundsLike;
  };

  const handleLocationSearch =
    (placeName: string) =>
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (placeName === "") return;

      const lngLatBoundsLike: LngLatBoundsLike | null = await searchGeocode(
        placeName
      );
      if (lngLatBoundsLike === null) return;
      const fitBoundsOptions: FitBoundsOptions = { maxZoom: 13 };
      const currentMap: Map = map.current;
      currentMap.fitBounds(lngLatBoundsLike!, fitBoundsOptions);
    };

  const initMap = (): void => {
    if (map.current) return; //stops map from intializing more than once

    const style: StyleSpecification = {
      version: 8,
      sources: {
        OSM: {
          type: "raster",
          tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
          tileSize: 256,
          attribution: "OpenStreetMap",
        },
        MAPBOX_MONOCHROME: {
          type: "raster",
          tiles: [`https://api.mapbox.com/styles/v1/tcjn-dev/clbvyym2n000414oun9p1h3o0/tiles/256/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`],
          tileSize: 256,
          attribution:
            '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a> ',
        },
      },
      layers: [
        {
          id: "basemap",
          type: "raster",
          source: "MAPBOX_MONOCHROME",
          minzoom: 0,
          maxzoom: 18,
        },
      ],
    };
    const options: MapOptions = {
      container: mapContainer.current,
      style: style,
      center: [bounds.initialLng, bounds.initialLat], // 市の中心の latlng を初期値に設定
      zoom: zoom,
      minZoom: 1, // 市全域が見えるように最小ズームを設定
      maxZoom: 17, // これ以上よると地図が見えなくなるので最大ズームを設定
      // 市に範囲を限定。上下左右に0.5度ずつ余裕を持たせる
      maxBounds: [
        [bounds.minLng-0.5,bounds.minLat-0.5],
        [bounds.maxLng+0.5,bounds.maxLat+0.5],
      ],
    };

    map.current = new maplibregl.Map(options);
  };

  const handleChangeOpacity = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();

    const newOpacity: number = parseInt(event.target.value);
    setOpacity(newOpacity);
    map.current.setPaintProperty(
      hazardLayerName,
      "raster-opacity",
      newOpacity / 100
    );
  };

  const loadGeoJsonLayer = async (geoJsonUrl: string): Promise<void> => {
    const geoJson = await fetch(geoJsonUrl)
      .then((response) => response.json())
      .then((data) => data);
    const layerName = geoJson.name ? geoJson.name : "area";
    map.current.addSource(layerName, {
      type: "geojson",
      data: geoJson,
      maxzoom: 18,
    });
    map.current.addLayer({
      id: layerName,
      type: "fill",
      source: layerName,
      paint: {
        "fill-color": "#333333",
        "fill-opacity": 0.7,
      },
    });
    map.current.addLayer({
      id: layerName+"-line",
      type: "line",
      source: layerName,
      paint: {
        "line-color": "#467703",
        "line-width": 4,
      },
    });
  }

  useEffect(() => {
    const currentMap: Map = map.current!;
    if (currentMap) return; //stops map from intializing more than once
    initMap();

    map.current.on("load", async () => {
      loadGeoJsonLayer(areaGeoJsonUrl);
      map.current.addSource(hazardLayerName, {
        type: "raster",
        tiles: [freezingMapTileUrl],
        tileSize: 256,
        minzoom: 10,
        maxzoom: 17,
      });

      map.current.addLayer({
        id: hazardLayerName,
        type: "raster",
        source: hazardLayerName,
        paint: {
          "raster-opacity": 0.8,
        },
      });

      const scale = new maplibregl.ScaleControl({
        maxWidth: 80,
        unit: "metric",
      });
      map.current.addControl(scale);
    });
  });

  return (
    <div className="map-wrap">
      <LocationSearchForm
        handleSubmit={handleLocationSearch}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />

      <div ref={mapContainer} className="map" style={{position:"absolute", top:0, left:0, width:"100%", height:"100%"}}/>

      <LayerOpacityControlBar
        handleChangeOpacity={handleChangeOpacity}
        opacity={opacity}
      />
      <Legend></Legend>
    </div>
  );
};

export { HazardMap };
